import React, { Component } from "react"
import { Link } from "gatsby"
import { BsList, BsX } from "react-icons/bs"
import "../../assets/style/components/_header.scss"
import Button from "../ui/Button"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      matches: true,
      menuOpen: false,
    }
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({
        matches: window.matchMedia("(min-width: 992px)").matches,
      })
    }
    const handler = e => this.setState({ matches: e.matches })
    window.matchMedia("(min-width: 992px)").addListener(handler)
  }
  toggleMenu = () => {
    this.setState({menuOpen: !this.state.menuOpen})
}
  render() {
    return (
      <>
        {this.state.matches === false ? (
          <nav
            className="navbar navbar-expand-lg navbar-dark mobile-menu"
            style={{
              position: `${
                this.state.menuOpen === true ? "fixed" : "relative"
              }`,
            }}
          >
            <div className="container-fluid navigation-container">
              <Link to="/" className="logo-link">
                <img
                  alt="tv-lahr"
                  src="https://www.datocms-assets.com/77261/1661699345-logo.png"
                />
                Turnverein Lahr
              </Link>
              <div
                className="mobile-menu-btn"
                onClick={() => this.toggleMenu()}
              >
                {this.state.menuOpen === false ? <BsList /> : <BsX />}
              </div>
              {this.state.menuOpen === true && (
                <div className="mobile-menu-content">
                  <ul>
                    <li>
                      {" "}
                      <Link to="/news/">News</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/sportarten/">Sportarten</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link target="_blank" to="https://widgets.yolawo.de/w/673c631d8419f678cba122ea">Kurse</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/verein/">Verein</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/downloads/">TV Aktuell</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/kontakt/">Kontakt</Link>{" "}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </nav>
        ) : (
          <nav className="main-container navbar navbar-expand-lg navbar-dark">
            <div className="container">
              <Link to="/" className="logo-link">
                <img
                  alt="tv-lahr"
                  src="https://www.datocms-assets.com/77261/1661699345-logo.png"
                />
                Turnverein Lahr
              </Link>
              <div
                className="collapse navbar-collapse custom-menu"
                id="main_nav"
              >
                <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <Link className="nav-link" to="/news/">
                      {" "}
                      News{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/sportarten/">
                      {" "}
                      Sportarten{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                      {" "}
                      <Link className="nav-link" target="_blank" to="https://widgets.yolawo.de/w/673c631d8419f678cba122ea">Kurse</Link>{" "}
                    </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/verein/">
                      {" "}
                      Verein{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/downloads/">
                      {" "}
                      TV Aktuell{" "}
                    </Link>
                  </li>
                  </ul>
                  <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link className="nav-link" to="/kontakt/">
                      {" "}
                      Kontakt{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Button url="/formulare/" type="primary">Zu den Formularen</Button>
                  </li>
                </ul>
                
              </div>
            </div>
          </nav>
        )}
      </>
    )
  }
}
export default Header
